<template>
  <div class="m-index">
    <TopBar :type="1" :logo="logo" />
    <!-- <div class="service" v-if="bottomInfo.waiter_qrcode" @click="showCode = true">联系<br />客服</div> -->
    <!-- banner图&图标跳转 -->
    <div class="banner_cont" v-if="bannerList.length>0">
        <div class="swiper_cont">
            <swiper
                class="m-banner"
                ref="bannerSwiper"
                :options="swiperOptionsBanner"
                v-if="bannerList.length"
                >
                <swiper-slide
                    v-for="(item, index) in bannerList"
                    :key="item.image + '_' + index"
                >
                <img v-if="!item.link" class="banner-img" :src="item.image" />
                <a :href="item.link" v-else>
                  <img class="banner-img" :src="item.image" />
                </a>
                </swiper-slide>
                <!-- <div class="swiper-pagination" slot="pagination"></div> -->
            </swiper>
        </div>
        <div class="menu_cont" v-if="siteInfo&&siteInfo.id&&siteInfo.id==73">
            <ul class="flex">
                <a href="https://www.wjx.cn/vm/hiRVLgn.aspx#" target="_blank" rel="noopener noreferrer">
                <li>
                    <p class="icon"><img src="@/assets/m/newIndex/icon1.png" alt=""></p>
                    <p class="text">定制授课</p>
                </li>
                </a>
                <a href="https://npx.kskstudy.com/teacherList" target="_blank" rel="noopener noreferrer">
                <li>
                    <p class="icon"><img src="@/assets/m/newIndex/icon2.png" alt=""></p>
                    <p class="text">大国工匠</p>
                </li>
                </a>
                <a href="https://zgyxk.kskstudy.com/researchList" target="_blank" rel="noopener noreferrer">
                <li>
                    <p class="icon"><img src="@/assets/m/newIndex/icon3.png" alt=""></p>
                    <p class="text">企业研学</p>
                </li>
                </a>
                <a href="https://npx.kskstudy.com/brand/list?type=1" target="_blank" rel="noopener noreferrer">
                <li>
                    <p class="icon"><img src="@/assets/m/newIndex/icon4.png" alt=""></p>
                    <p class="text">更多专题</p>
                </li>
                </a>

                
            </ul>
        </div>
    </div>

    <div class="container_cont_data">
        <!-- 创新思维与方法 -->
        <div class="cont_item cont1">
            <div class="title_cont_info flex">
                <p class="text">{{showText}}</p>
                <div class="more_btn flex" v-if="siteInfo&&siteInfo.id&&siteInfo.id==73"  @click="goNewUrl('https://npx.kskstudy.com/')">
                    <span>更多</span>
                    <img src="@/assets/m/newIndex/right_icon1.png" alt="">
                </div>
                <div class="more_btn flex" v-else  @click="$router.push('/courseList')">
                    <span>更多</span>
                    <img src="@/assets/m/newIndex/right_icon1.png" alt="">
                </div>
            </div>
            <div class="tab_cont_list">
                <ul>
                   <li
                    class="cursor"
                    v-for="item in moduleList1"
                    :key="item.id"
                    :class="modalId1 == item.id ? 'active' : ''"
                    @click="changModal(item.id,'1')"
                    >
                    {{ item.name }}
                    </li>
                </ul>
            </div>
            <div class="course_list">
                <ul>
                    <li v-for="item in courseList1" :key="item.id" @click="goCourseDetail(item)">
                        <p class="img"><img :src="item.image" alt=""></p>
                        <p class="text overflow_e">{{ item.name }}</p>
                    </li>
                </ul>
            </div>
        </div>
        <div class="cont_item cont1" v-if="moduleList2.length>0">
            <div class="title_cont_info flex">
                <p class="text" v-if="siteInfo&&siteInfo.id&&siteInfo.id==73">创新思维与创新方法</p>
                <p class="text" v-else>专业能力提升培训课程</p>
                <div class="more_btn flex" v-if="siteInfo&&siteInfo.id&&siteInfo.id==73"  @click="goNewUrl('https://npx.kskstudy.com/')">
                    <span>更多</span>
                    <img src="@/assets/m/newIndex/right_icon1.png" alt="">
                </div>
                <div class="more_btn flex" v-else  @click="$router.push('/courseList')">
                    <span>更多</span>
                    <img src="@/assets/m/newIndex/right_icon1.png" alt="">
                </div>
            </div>
            <div class="tab_cont_list">
                <ul>
                   <li
                    class="cursor"
                    v-for="item in moduleList2"
                    :key="item.id"
                    :class="modalId2 == item.id ? 'active' : ''"
                    @click="changModal(item.id,'2')"
                    >
                    {{ item.name }}
                    </li>
                </ul>
            </div>
            <div class="course_list">
                <ul>
                    <li v-for="item in courseList2" :key="item.id" @click="goCourseDetail(item)">
                        <p class="img"><img :src="item.image" alt=""></p>
                        <p class="text overflow_e">{{ item.name }}</p>
                    </li>
                </ul>
            </div>
        </div>

        <!-- 大师来了 -->
        <div class="teacher_cont_box" v-if="teacherList.length>0">
            <div class="title_cont">
                <p class="title"><img src="@/assets/m/newIndex/text_icon.png" alt=""></p>
                <div class="more_btn flex"  @click="goNewUrl('https://npx.kskstudy.com/teacherList')">
                    <span>更多</span>
                    <img src="@/assets/m/newIndex/right_icon2.png" alt="">
                </div>
            </div>
            <p class="tips_text">国家级工作室带头人入企指导</p>
            <div class="teacher_list_cont">
                <swiper
                    class="teacherSwiper"
                    ref="bannerSwiper"
                    :options="teacherOptions"
                    v-if="teacherList.length"
                    >
                    <swiper-slide
                        class="slide_item"
                        v-for="(item) in teacherList"
                        :key="item.id"
                    >
                        <p class="img">
                            <img :src="item.avatar" alt="">
                        </p>
                        <div class="cont_info">
                            <p class="name">{{item.name}}</p>
                            <p class="level">{{item.level}}</p>
                            <div class="desc overflow_3">{{item.introduce}}</div>
                        </div>
                        <!-- <a :href="item.link">
                        <img class="banner-img" :src="item.image" />
                        </a> -->
                    </swiper-slide>
                    <!-- <div class="swiper-pagination" slot="pagination"></div> -->
                </swiper>
            </div>
        </div>
         <a href="https://www.wjx.cn/vm/hiRVLgn.aspx#" target="_blank" v-if="siteInfo&&siteInfo.id&&siteInfo.id==73">
            <div class="line_img">
                <img src="@/assets/m/newIndex/line_img1.png" alt="">
            </div>
        </a>
        <!-- 创新工作室-工作室解读 -->
        <div class="cont_item cont2" v-for="item in contDataInfo" :key="item.id">
            <div class="title_cont_info flex">
                <p class="text overflow_o">{{item.name}}</p>
                <div class="more_btn flex"  @click="goNewUrl('https://npx.kskstudy.com/')">
                    <span>更多</span>
                    <img src="@/assets/m/newIndex/right_icon1.png" alt="">
                </div>
            </div>
            <div class="course_cont_list">
                <ul>
                    <li v-for="list in item.list" :key="list.id" @click="goBox3Detail(list)">
                        <p class="img"><img :src="list.image" alt=""></p>
                        <div class="info_cont">
                            <p class="name overflow_2">{{list.name}}</p>
                            <p class="time">{{list.created_at}}</p>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
        <a href="https://www.wjx.cn/vm/hiRVLgn.aspx#" target="_blank" v-if="siteInfo&&siteInfo.id&&siteInfo.id==73">
            <div class="line_img last_img">
                <img src="@/assets/m/newIndex/line_img2.png" alt="">
            </div>
        </a>
    </div>

    <div class="deep_cont_box">
        <div class="cont_info">
            <div>
                <img src="@/assets/m/newIndex/phone_icon.png" alt="">
                <div class="text flex">
                    <p>热线电话：</p><span>400-0123-823</span>
                </div>
            </div>
            <div class="text">
               <p> 技术支持：中工科创</p>
            </div>
        </div>
        <div class="code_text">
            Copyright © 2015-{{new Date().getFullYear()}} kskstudy.com All Rights Reserved 京ICP备16012224号-3
        </div>
    </div>
    
  </div>
</template>
<script>
import { mapMutations } from "vuex";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/css/swiper.css";
import TopBar from "@/m/common/topBar.vue";
export default {
  name: "Index",
  components: {
    TopBar,
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      logo: "",
      
      bannerList: [],
      moduleList:[],//创新思维与创新方法 分类
      moduleList1:[],//创新思维与创新方法 分类
      moduleList2:[],//创新思维与创新方法 分类
      modalId1:'',
      modalId2:'',
      courseList1:[],//创新思维与创新方法
      courseList2:[],//创新思维与创新方法
      teacherList:[],//大师来了
      showTeacher:'',//当前显示讲师
      contDataInfo:[],


      //banner
      swiperOptionsBanner: {
        slidesPerView: 1, //显示slide的数量
        centeredSlides: true, //居中显示
        spaceBetween: 14, //间距
        loop: true, //循环
        observer: true, //修改swiper自己或子元素时，自动初始化swiper
        observeParents: true, //修改swiper的父元素时，自动初始化swiper
        autoplay: {
          delay: 3000,
          stopOnLastSlide: false,
          disableOnInteraction: true,
        },
        //setWrapperSize :true,  // Swiper使用flexbox布局(display: flex)，
        pagination: {
          el: ".swiper-pagination",
          clickable: true, //是否可点击
        },
      },
      siteInfo:'',
        //   讲师轮播图
       teacherOptions:{
            slidesPerView: 1.2, //显示slide的数量
            centeredSlides: true, //居中显示
            spaceBetween: 14, //间距
            loop: true, //循环
            observer: true, //修改swiper自己或子元素时，自动初始化swiper
            observeParents: true, //修改swiper的父元素时，自动初始化swiper
            autoplay: {
            delay: 300000,
            stopOnLastSlide: false,
            disableOnInteraction: true,
            },
       },
       showText:'管理干部培训课程',
     
    };
  },
  metaInfo() {
    return {
      meta: [
        // { name: "keywords", content: this.setting.keywords },
        // { name: "description", content: this.setting.description },
      ],
    };
  },
  mounted() {
    if(window.location.host=='cxgzspx.kskstudy.com'){
        this.showText = '创新思维与创新方法'
    }else if(window.location.host=='wxbz.kskstudy.com'){
        this.showText = '五型班组培训课程'
    }else if(window.location.host=='scglz.kskstudy.com'){
        this.showText = '制造业生产管理者能力提升'
    }else{
        this.showText = '管理干部培训课程'
    }
    this.siteInfo = localStorage.getItem('siteInfo')? JSON.parse(localStorage.getItem('siteInfo')):''
    this.getWebsiteInfo(); //获取网站信息
    this.getBanner();
    this.getModule(); //获取首页模块
    this.websiteData = this.$store.state.websiteState;

    //微信登录
    if (this.$route.query.noncestr) {
      this.weLoin(this.$route.query.noncestr);
    }
  },
  methods: {
    ...mapMutations(["ShowMLoginModal", "ShowTipModal", "CloseTipModal"]), //登录弹窗
    
    changModal(item,num){
      if(num==1){
        this.modalId1=item
      }else{
        this.modalId2 = item
      }
        this.getCourseList(item,num)
    },
    goCourseDetail(item){
        this.$router.push('/newRecordedInfo/'+item.data_id)
    },

    goBox3Detail(item){
        if(item.pc_link){
            window.open(item.pc_link)
        }
    },
    goNewUrl(url){
        window.open(url)
    },
    // 获取banner图
    getBanner() {
      this.$axios
        .post(`/v1/home/banner`, {
          type: "2",
        })
        .then((res) => {
          if (res.data.length == 1) {
            this.swiperOptionsBanner.loop = false;
          }
          this.bannerList = res.data;
        });
    },
    //获取首页模块
    getModule() {
      this.$axios.post(`/v1/home/module`).then((res) => {
        if(res.code==0){
            let contBox3 = []
            res.data.forEach(el=>{
                if(el.type==1){
                    this.moduleList.push(el)
                }
                if(el.type==5){
                    this.getTeacher(el.id)
                }
                if(el.type==9){
                    contBox3.push(el)
                }
            })
           if(this.moduleList.length>6){
              this.moduleList1 = this.moduleList.slice(0,6)
              this.moduleList2 = this.moduleList.slice(6,this.moduleList.length)
              this.modalId2 = this.moduleList2[0].id
              this.getCourseList(this.modalId2,'2')
            }else{
              this.moduleList1 = this.moduleList
            }
            this.modalId1 = this.moduleList1[0].id
            this.getCourseList(this.modalId1,'1')
            
             this.getModuleData(contBox3);
        }
       
      });
    },
    // 获取 创新思维与创新方法 列表
    getCourseList(id,num){
        this.$axios.post(`/v1/home/moduleData`,{id:id}).then(res=>{
           if(num==1){
              this.courseList1 = res.data.data
            }else{
              this.courseList2 = res.data.data
            }
        })
    },
    // 获取 大师来了 列表
    getTeacher(id){
        this.$axios.post(`/v1/home/moduleData`,{id:id}).then(res=>{
            let data = []
            if(res.data.length>4){
                data = res.data.splice(0,4)
            }else{
                data = res.data
            }
            data.forEach(el=>{
              el.course=[]
              el.honor=[]
            })
            this.teacherList = data
            this.getTeacherDetail(this.teacherList[0])
        })
    },
    getTeacherDetail(item){
      this.showTeacher = item
      this.$axios.post(`/v1/home/teacherTag`,{teacher_id:item.teacher_id}).then(res=>{
        if(res.code==0){
          this.showTeacher.course = res.data.course
          this.showTeacher.honor = res.data.honor
          console.log(this.showTeacher)
        }
      })
      
    },
    //获取各模块数据
    async getModuleData(moduleData) {
      this.contDataInfo = moduleData;

    //   if (this.contDataInfo <= 0) {
    //     this.loadState = false;
    //     setTimeout(() => {
    //       this.loadState1 = false;
    //     }, 1000);
    //   }
      for (let i = 0; i < moduleData.length; i++) {
        let res = await this.$axios.post(`/v1/home/moduleData`, {
          id: moduleData[i].id,
        });

        for (let i = 0; i < this.contDataInfo.length; i++) {
          if (res.data.total) {
            for (let o = 0; o < res.data.data.length; o++) {
              if (res.data.data[o].module_id == this.contDataInfo[i].id) {
                if (this.contDataInfo[i].list) {
                  this.$set(this.contDataInfo[i].list, o, res.data.data[o]);
                } else {
                  this.$set(this.contDataInfo[i], "list", []);
                  this.$set(this.contDataInfo[i].list, o, res.data.data[o]);
                }
              }
            }
          } else {
            for (let o = 0; o < res.data.length; o++) {
              if (res.data[o].module_id == this.contDataInfo[i].id) {
                if (this.contDataInfo[i].list) {
                  this.$set(this.contDataInfo[i].list, o, res.data[o]);
                } else {
                  this.$set(this.contDataInfo[i], "list", []);
                  this.$set(this.contDataInfo[i].list, o, res.data[o]);
                }
              }
            }
          }
        }
        this.loadState = false;
        setTimeout(() => {
          this.loadState1 = false;
        }, 1000);
        // this.loading.close();
      }
    },
    
    //获取网站信息
    getWebsiteInfo() {
      this.$axios.post(`/v1/websiteInfo`, { is_pc: 2 }).then((res) => {
        this.bottomInfo = res.data.site_info;
        localStorage.setItem("siteInfo", JSON.stringify(res.data.site_info));
        localStorage.setItem(
          "navigation",
          JSON.stringify(res.data.navigation)
        );
        this.logo = res.data.site_info.site_logo;
        this.menuList = res.data.navigation; //获取金刚区菜单
        document.getElementById("titleName").innerText =
          res.data.site_info.name;
        //  this.getMeat();
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.m-index {
  background-color: #f7f7f7;
}
.banner_cont{
    background: #fff;
    box-sizing: border-box;
    padding: 28px 32px 50px;
    // 轮播图
    .swiper_cont{
        width: 100%;
        height: 272px;
        .m-banner{
            width: 100%;
            img{
                width: 100%;
                height: 272px;
            }
        }
    }
    .menu_cont{
        margin-top: 44px;
        ul{
            justify-content: center;
            a{
                flex: 1;
            }
            li{
                
                
                p.icon{
                    width: 96px;
                    height: 96px;
                    margin: 0 auto;
                    img{
                        width: 100%;
                        height: 100%;
                    }
                }
                p.text{
                    margin-top: 12px;
                    font-size: 28px;
                    font-family: PingFang SC, PingFang SC;
                    font-weight: 400;
                    color: #333333;
                    text-align: center;
                }
            }
            li:nth-child(4n){
                margin-right: 0;
            }
        }
    }
}

.container_cont_data{
    margin-top: 20px;
    .cont_item{
      background: #fff;
      box-sizing: border-box;
      padding: 52px 32px;
      .title_cont_info{
        justify-content: space-between;
        p.text{
            font-size: 36px;
            font-family: PingFang SC, PingFang SC;
            font-weight: 500;
            color: #333333;
            flex: 1;
        }
        .more_btn{
            margin-left: 20px;
            span{
                font-size: 28px;
                font-family: PingFang SC, PingFang SC;
                font-weight: 400;
                color: #999999;
            }
            img{
                width: 28px;
                height: 32px;
            }
        }
      }
    }
    
    // 创新思维与方法
    .cont1{
        .tab_cont_list{
            margin-top: 32px;
            ul{
                display: flex;
                flex-wrap: nowrap;
                overflow-x: auto;
                li{
                    // float: left;
                    flex: none;
                    height: 56px;
                    border-radius: 56px;
                    opacity: 1;
                    border: 2px solid #AAAAAA;
                    line-height: 56px;
                    padding: 0 32px;
                    margin-right: 24px;
                    font-size: 28px;

                    
                }
                li.active{
                    background: #036AE2;
                    border: 2px solid #036AE2;
                    color: #fff;
                }
                li:last-child{
                    margin-right: 0;
                }
            }
        }
        .course_list{
            margin-top: 18px;
            ul{
                display: flex;
                flex-wrap: wrap;
                li{
                    width: 48%;
                    margin-right: 4%;
                    margin-bottom: 48px;
                    p.img{
                        width: 328px;
                        height: 178px;
                        img{
                            width: 100%;
                            height: 100%;
                            border-radius: 5px;
                        }
                    }
                    p.text{
                        font-size: 28px;
                        font-family: PingFang SC, PingFang SC;
                        font-weight: 500;
                        color: #333333;
                        line-height: 36px;
                        margin-top: 12px;
                    }
                }
                li:nth-child(2n){
                    margin-right: 0;
                }
            }
        }
    }

    // 大师来了
    .teacher_cont_box{
        background: url('~@/assets/m/newIndex/teacher_bgm.png') no-repeat;
        background-size: 100% 380px;
        margin-bottom: 80px;
        .title_cont{
            padding-top: 18px;
            position: relative;
            p.title{
                width: 232px;
                height: 60px;
                margin: 0 auto;
                img{
                    width: 100%;
                }
            }
            .more_btn{
                position: absolute;
                right: 32px;
                top: 22px;
                span{
                    font-size: 28px;
                    font-family: PingFang SC, PingFang SC;
                    font-weight: 400;
                    color: #FFFFFF;
                }
                img{
                    width: 28px;
                    height: 32px;
                }
            }
        }
        p.tips_text{
            font-size: 28px;
            font-family: PingFang SC, PingFang SC;
            font-weight: 500;
            color: #FFFFFF;
            line-height: 36px;
            text-align: center;
            margin-top: 24px;
        }
        .teacher_list_cont{
            margin-top: 32px;
            .teacherSwiper{
                .slide_item{
                    width: 630px;
                    height: 326px;
                    background: #FFFFFF;
                    box-shadow: 0px 8px 20px 0px rgba(0,0,0,0.08);
                    border-radius: 24px 24px 24px 24px;
                    box-sizing: border-box;
                    padding: 40px;
                    display: flex;
                    p.img{
                        width: 194px;
                        height: 246px;
                        img{
                            width: 100%;
                            height: 100%;
                            object-fit: cover;
                        }
                    }
                    .cont_info{
                        flex: 1;
                        margin-left: 36px;
                        p.name{
                            font-size: 36px;
                            font-family: PingFang SC, PingFang SC;
                            font-weight: 500;
                            color: #3D3D3D;
                        }
                        p.level{
                            margin-top: 12px;
                            font-size: 24px;
                            font-family: PingFang SC, PingFang SC;
                            font-weight: 500;
                            color: #E79E0C;
                            background: #F9F1E6;
                            display: inline-block;
                            padding: 10px 20px;
                            border-radius: 40px;
                        }
                        div.desc{
                            margin-top: 12px;
                            font-size: 26px;
                            font-family: PingFang SC, PingFang SC;
                            font-weight: 400;
                            color: #333333;
                        }
                    }
                }
            }
        }
    }


    // 创新工作室
    .cont2{
        margin-bottom: 20px;
        .course_cont_list{
            margin-top: 36px;
            ul{
                display: flex;
                flex-wrap: wrap;
                li{
                    display: flex;
                    border-bottom: 1px solid #EBEBEB;
                    padding-bottom: 42px;
                    margin-bottom: 38px;
                    width: 100%;
                    p.img{
                        width: 276px;
                        height: 152px;
                        border-radius: 8px 8px 8px 8px;
                        img{
                            width: 100%;
                            height: 100%;
                            border-radius: 8px;
                        }
                    }
                    .info_cont{
                        flex: 1;
                        margin-left: 24px;
                        display: flex;
                        flex-direction: column;
                        justify-content: space-between;
                        p.name{
                            font-size: 30px;
                            font-family: PingFang SC, PingFang SC;
                            font-weight: 500;
                            color: #333333;
                        }
                        p.time{
                            font-size: 24px;
                            font-family: Source Han Sans, Source Han Sans;
                            font-weight: 400;
                            color: #999999;
                            margin-top: 30px;
                        }
                    }
                }
                li:last-child{
                    border: none;
                    padding-bottom: 0;
                    margin-bottom: 0;
                }
            }
        }
    }
}
.line_img{
    img{
        width: 100%;
        display: block;
    }
}
.last_img{
    margin-top: -20px;
}

.deep_cont_box{
    width: 100%;
    height: 212px;
    background: #292F36;
    box-sizing: border-box;
    padding: 48px 40px 0;
    .cont_info{
        display: flex;
        justify-content: space-between;
        >div{
            display: flex;
            justify-content: space-between;
            font-size: 28px;
            font-family: PingFang SC, PingFang SC;
            font-weight: 400;
            color: #FFFFFF;
            img{
                width: 40px;
                height: 40px;
            }
            span{
                color: #1E84FF;
                 opacity: 1;
            }

        }
        .text{
             p{
                opacity: .5;
             }
        }
    }
    .code_text{
        font-size: 24px;
        font-family: PingFang SC, PingFang SC;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 28px;
        text-align: center;
        margin-top: 28px;
        opacity: .5;
        line-height: 40px;
    }
}

::-webkit-scrollbar {
      width: 0;
    }
::-webkit-scrollbar {
      width: 0;
    }
    /* 隐藏 IE 和 Edge 浏览器的滚动条 */
    ::-ms-scrollbar {
      width: 0;
    }
.flex {
  display: flex;
  align-items: center;
}
.cursor {
  cursor: pointer;
}
.overflow_o {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.overflow_2 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.overflow_3 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}
.overflow_4 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}
</style>
